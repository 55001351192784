// Default variables = https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less
@import "./theme/nz-variables.less";
@import "./theme/nz-components.less";
@import "./theme/nz-style.less";


.grecaptcha-badge { display: none !important; }

.ant-input-affix-wrapper > input.ant-input {
    background: unset;
}