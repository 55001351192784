@import "./customization/index.less";

html { font-size: @font-size-base; }

:root {
    --cwt-media-background: #eceff3;
    --cwt-media-border: 1px solid @border-color-base;
    --cwt-media-border-radius-default: 2px;
    --cwt-media-border-radius-round: 4px;
    --cwt-media-border-radius-circle: 100rem;

    --cwt-media-border-default: 2px;
    --cwt-media-border-outline: 1px solid #d9d9d9;
    --cwt-media-border-dashed: 1px dashed #d9d9d9;
}

.clickable {
    cursor: pointer !important;
}

.draggable {
    cursor: grab !important;
}

.no-click {
    pointer-events: none;
}

.ant-space.ant-space-vertical {
    display: flex;
}
.ant-table-column-title {
    white-space: nowrap;
}

.borderless {
    border: none !important;
}

.shadowless {
    box-shadow: none !important;
}

.ant-form-vertical .ant-form-item-label > label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    display: inline-block;
    vertical-align: middle;
}

.ant-form-item-label > label .ant-form-item-tooltip {
    margin-inline-start: 0.33333rem;
}

.ant-form-vertical .ant-form-item {
    flex-wrap: nowrap;
}

h6.ant-typography,
div.ant-typography-h6,
div.ant-typography-h6 > textarea,
.ant-typography h6 {
    font-size: @heading-6-size;
    font-weight: 500;
    line-height: 1.5;
}

ngx-extended-pdf-viewer .html {
    border-radius: 4px;
    overflow: hidden;
}

.anticon {
    vertical-align: middle;
}

.ant-progress-text {
    text-align: center;
}

.ant-spin-nested-loading > div > .ant-spin {
    max-height: unset;
}

.ant-btn-icon-search {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    color: #001E6C;
    background-color: #A4E8FF;
}

.ant-btn .anticon {
    vertical-align: 0.05em;
}

.ant-transfer-list { flex: 1; }
.ant-transfer-operation { flex-shrink: 0;}

.ant-tabs-top > .ant-tabs-nav::before {
    display: none !important;
}