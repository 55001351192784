.ant-card {
    background-color: #fff;
    border: none;
    
    > .ant-card-body {
        padding: 1rem;
    }

    > .ant-card-head {
        border: none;
        padding: 1rem;
        padding-bottom: 0;
    }

    .ant-card-head-title {
        padding: 0;
        padding-left: 10px;
        position: relative;
        font-size: 18px;
        line-height: 22px;
        color: @primary-color;
        &::before {
            content: " ";
            display: block;
            width: 3px;
            height: 22px;
            border-radius: 2px;
            background-color: @secondary-color;
            position: absolute;
            left: 0;
        }
    }

    &.flex-card {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        > *:not(.ant-card-body) {
            flex-shrink: 0;
        }
    
        > .ant-card-body {
            flex: 1;
            min-height: 0;
        }
    }

    &.compact-card {
        > .ant-card-body {
            padding: 0.5rem 1rem;
        }
    }

    &.scroll-card {
        overflow: hidden;
        > .ant-card-body {
            overflow-y: auto;
        }
    }
}