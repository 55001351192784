.ant-btn {
    border-radius: 13px;
    &.btn-secondary{
        background:fade(@secondary-color,30);
        border:unset;
        border: 1px;
        background-color: #FFFFFF;
        color: @secondary-color;
        border: 1px solid @secondary-color;
        &:hover{
            background:fade(@secondary-color,40);
        }
    }

    &.btn-primary{
        background:@primary-color;
        color:#FFFFFF;
        border:unset;
        width: 239px;
        height: 52px;
    }

}