/* Firefox */
* {
    scrollbar-width: 8px;
    scrollbar-color: #F1F1F1 #FFFFFF;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    &:hover {
        background-color: #FFFFFF;
    }
    &:active {
        background-color: #FFFFFF;
    }
}

*::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #E1E1E1;
    border: 1px solid #FFFFFF;
    &:hover {
        background-color: #D1D1D1;
    }
    &:active {
        background-color: #E8E8E8;
    }
}